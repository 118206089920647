import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col } from "react-bootstrap"
import elephant from "../../images/topic-3/overview/elephant-1.jpg"
import step1 from "../../images/topic-3/overview/section-1.jpg"
import step2 from "../../images/topic-3/overview/section-2.jpg"
import step3 from "../../images/topic-3/overview/section-3.jpg"
import step4 from "../../images/topic-3/overview/section-4.jpg"
import step5 from "../../images/topic-3/overview/section-5.jpg"
import step6 from "../../images/topic-3/overview/section-6.jpg"
import BottomNav from "../../components/bottomNav"
import MyBreadcrumb from "../../components/myBreadcrumb"
import Instructions from "../../components/instructions"
import TopHeader from "../../components/topHeader"
import LinkSteps from "../../components/linkSteps2"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const EPopGrowth = ({ location }) => {
  const { t } = useTranslation()

  const pageContent = {
    breadcrumb: parse(t("Topic3_Overview_breadcrumb")),
    title: parse(t("Topic3_Overview_title")),
    topText: parse(t("Topic3_Overview_topText")),
    images: [
      {
        src: elephant,
        alt: parse(t("Topic3_Overview_images[0].alt")),
        caption: parse(t("Topic3_Overview_images[0].caption")),
      },
      {
        src: step1,
        alt: parse(t("Topic3_Overview_images[1].alt")),
        caption: parse(t("Topic3_Overview_images[1].caption")),
      },
      {
        src: step2,
        alt: parse(t("Topic3_Overview_images[2].alt")),
        caption: parse(t("Topic3_Overview_images[2].caption")),
      },
      {
        src: step3,
        alt: parse(t("Topic3_Overview_images[3].alt")),
        caption: parse(t("Topic3_Overview_images[3].caption")),
      },
      {
        src: step4,
        alt: parse(t("Topic3_Overview_images[4].alt")),
        caption: parse(t("Topic3_Overview_images[4].caption")),
      },
      {
        src: step5,
        alt: parse(t("Topic3_Overview_images[5].alt")),
        caption: parse(t("Topic3_Overview_images[5].caption")),
      },
      {
        src: step6,
        alt: parse(t("Topic3_Overview_images[6].alt")),
        caption: parse(t("Topic3_Overview_images[6].caption")),
      },
    ],
    middleText: parse(t("Topic3_Overview_middleText")),
    bottomText: parse(t("Topic3_Overview_bottomText")),
    instructions: parse(t("Topic3_Overview_instructions")),
  }
  // =========================================================================================================
  return (
    <Layout>
      <SEO title={parse(t("Topic3_Overview_pageTitle"))} />

      <MyBreadcrumb
        topicTitle=""
        secondTitle=""
        currentTitle={pageContent.breadcrumb}
      />

      <div className="homeContent">
        <Row>
          <Col sm="8">
            <TopHeader
              title={pageContent.title}
              content={pageContent.topText}
            />
          </Col>
          <Col sm="4">
            <img
              src={pageContent.images[0].src}
              alt={pageContent.images[0].alt}
              className="roundMe"
              style={{ maxWidth: `290px`, marginBottom: `1.45rem` }}
            />
            <p className="smText">{pageContent.images[0].caption}</p>
          </Col>
        </Row>

        <Row>
          <Col sm="12" md="12">
            {pageContent.middleText}
          </Col>
        </Row>

        <Row>
          <Col sm="12" md="8">
            {pageContent.bottomText}
          </Col>
        </Row>

        <Instructions content={pageContent.instructions} />

        <LinkSteps
          // titleColor="#990003"
          data={[
            {
              id: "section1",
              text: pageContent.images[1].caption,
              image: pageContent.images[1].src,
              alt: pageContent.images[1].alt,
            },
            {
              id: "section2",
              text: pageContent.images[2].caption,
              image: pageContent.images[2].src,
              alt: pageContent.images[2].alt,
            },
            {
              id: "section3",
              text: pageContent.images[3].caption,
              image: pageContent.images[3].src,
              alt: pageContent.images[3].alt,
            },
            {
              id: "section4",
              text: pageContent.images[4].caption,
              image: pageContent.images[4].src,
              alt: pageContent.images[4].alt,
            },
            {
              id: "section5",
              text: pageContent.images[5].caption,
              image: pageContent.images[5].src,
              alt: pageContent.images[5].alt,
            },
            {
              id: "section6",
              text: pageContent.images[6].caption,
              image: pageContent.images[6].src,
              alt: pageContent.images[6].alt,
            },
          ]}
        />

        <hr />
        <Row>
          <Col sm="12" md="12" className="alignRight">
            <BottomNav
              prevName={parse(t("Previous"))}
              btnLinkPrev="/"
              btnLinkNext="/epopulationgrowth/section1"
              nextName={parse(t("Next"))}
            />
          </Col>
        </Row>
      </div>
      <br />
    </Layout>
  )
}

export default EPopGrowth
