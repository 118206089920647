import React from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const LinkSteps2 = ({ title, titleColor, data }) => {
  return (
    <div>
      <p style={{ color: titleColor }}>{title}</p>

      <Row className="groupItemsOFF stepsMargin">
        {data.map((value, index) => {
          return (
            <Col xs="4" sm="3" md="3" lg key={index}>
              <div class="card2">
                <Link to={value.id}>
                  <img
                    src={value.image}
                    alt="Avatar"
                    style={{ width: "100%", marginBottom: "10px" }}
                    className="round topicImg"
                  />
                </Link>
                <Link to={value.id}>
                  <p className="text-center">{value.text}</p>
                </Link>
              </div>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default LinkSteps2
