import React from "react"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Instructions = ({ content, hidden }) => {
  return (
    <p className="borderMe" hidden={hidden}>
      <FontAwesomeIcon icon={faExclamationTriangle} />
      &nbsp; {content}
    </p>
  )
}

export default Instructions
